$primary: #15b298;
$navbar-breakpoint: 769px; // $tablet

@import '../node_modules/bulma/bulma.sass';
@import '../node_modules/@fortawesome/fontawesome-free/css/all.css';
@import url('https://fonts.googleapis.com/css?family=Roboto:300');

html {
    scroll-behavior: smooth;
}

#root {
    display: flex;
    min-height: 100vh;
    flex-direction: column;

    &> section {
        flex: 1 0 auto;
        //min-height: calc(50vh - 84px);
    }

    section.is-fullscreen {
        min-height: calc(100vh - 84px);

        .columns {
            min-height: 50vh;
        }

        img.illustration {
            max-height: 45vh;
        }
    }
}

section div.container {
    font-size: 14px;
}

section div.container header {
    margin-bottom: 3rem;
}

span.icon.is-large {
    margin-bottom: 1rem;
}

.hero {
    //background-image: url('img/hero.svg');
    //background-size: cover;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgb(247, 250, 255) 100%);
    background-repeat: repeat-x;
    position: relative;
}

#precario {
    //background-color: #15b298
    background-image: linear-gradient(rgb(247, 250, 255), rgba(255, 255, 255, 0) 100%);
    background-repeat: repeat-x;
    position: relative;
}

footer {
    font-size: 12px;
    padding: 3rem 1.5rem 4rem !important;

    a.badge {
        margin: 0 10px;
    }
}

div.back-to-top {
    position: fixed;
    bottom: 10px;
    right: 10px;

    .button.is-rounded {
        padding-left: 16px;
        padding-right: 16px;
    }
}

//
// helpers
//

.is-fit {
    width: 1%;
}

.is-nowrap {
    white-space: nowrap;

    .buttons {
        flex-wrap: nowrap;
    }
}

.ellipsis {
    // width: 100%;
    max-width: 1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

//
// bulma style -> react-select
//

.react-select__control {
    font-size: 1rem !important;
    height: 2.5em !important;
    line-height: 1.5 !important;

    color: $input-color !important;
    background-color: $input-background-color !important;
    border-color: $input-border-color !important;
    border-radius: $input-radius !important;

    &:hover {
        border-color: $input-hover-border-color !important;
    }

    .react-select__placeholder {
        color: $input-placeholder-color !important;
    }
}

//
// recipe details
//

@media screen and (max-width: 769px) {
    #recipe-details .columns {
        display: flex;
        flex-direction: column-reverse;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    #recipe-details .columns {
        display: flex;
        flex-direction: column-reverse;
    }
}

table.nostyle-6 {
    td, th {
        border: 0;
        padding: 6px 0 0 0;
    }

    tr:first-child {
        td, th {
            padding-top: 0;
        }
    }
}

table.nostyle-2 {
    td, th {
        border: 0;
        padding: 2px 0 0 0;
    }

    tr:first-child {
        td, th {
            padding-top: 0;
        }
    }
}

@keyframes horizontal-shaking {
    0% { transform: rotate(0) }
    25% { transform: rotate(1deg) }
    75% { transform: rotate(-1deg) }
    100% { transform: rotate(0) }
}


// https://stackoverflow.com/questions/2812770/add-centered-text-to-the-middle-of-a-horizontal-rule

.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #ddd;
}

.separator:not(:empty)::before {
  margin-right: .5em;
}

.separator:not(:empty)::after {
  margin-left: .5em;
}
